'use strict'

import Swiper from 'swiper'

let self

export default self = {
  container: null,

  init: () => {
    self.container = document.querySelectorAll('.artists-main .category-wrapper');
    if (self.container) self.caseStudyDetailSlide();
  },

  caseStudyDetailSlide: () => {

    // Add class name for each gallery container
    [...self.container].forEach((slider, index) => {
      return slider.classList.add(`slider-${index + 1}`);
    });

    // Create Each Swiper Object
    const sliderArray = [];
    const caseStudySwiper = document.querySelectorAll('.artists-main .swiper-container');

    [...caseStudySwiper].forEach((slider, index) => {
      sliderArray[index] = new Swiper(slider, {
        navigation: {
          nextEl: document.querySelector(`.slider-${index + 1} .artists-nav-next`),
          prevEl: document.querySelector(`.slider-${index + 1} .artists-nav-prev`),
        },
        speed: 800,
        slidesPerView: 4,
        effect: 'slide',
        breakpoints: {
          700: {
            slidesPerView: 2,
          }
        }
      });
    });

    // Init
    sliderArray.forEach(slider => slider.init());

  },


}