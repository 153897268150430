'use strict'

import $ from 'jquery'
import slick from 'slick-carousel-latest'

let self

export default self = {
  container: null,

  init: () => {
    self.container = document.querySelectorAll('.page-template-page-template-new');
    if (self.container) self.doJs();
  },

  doJs: () => {

    // $(document).ready(function() {
        $('.testimonials__slider').slick({
          arrows: false,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
          centerMode: true,
          centerPadding: '25%',
          responsive: [
            {
              breakpoint: 991,
              settings: {
                centerPadding: '10%',
                dots: true,
              }
            },
          ]
        });
        $('.artists__slider').slick({
          arrows: false,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
          centerMode: true,
          centerPadding: '0',
          slidesToShow: 3,
          // adaptiveHeight: true,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 1,
                centerPadding: '15%',
              }
            },
          ]
        });
      
        let slider = document.getElementById("myRange");
        // Update the current slider value (each time you drag the slider handle)
        if ( slider ) {
          slider.oninput = function() {
            // output.innerHTML = this.value;
            $('.artists__slider').slick('slickGoTo', this.value, true);
          }
        
          $('.artists__slider').on('afterChange', function(event, slick, currentSlide){
            $('#myRange').val(currentSlide);
        
            let val = $('#myRange').val();
            let max = $('#myRange').attr('max');
        
            $('#myRange').css('background', 'linear-gradient(to right, #fff 0%, #fff ' + val/max*100 + '%, #313131 ' + val/max*100 + '%, #313131 100%)');
          });
        }
      
    //   });
  },


}
