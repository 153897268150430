'use strict'

import Barba from 'barba.js';
import {TweenLite, TimelineLite} from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import Nav from './nav';
import Loading from './loading';
import ScrollRevealSiegel from './scrollReveal'
import Video from './video';
import HoverEffect from './hoverEffect';
import bodyMovin from './bodyMovin'
import InstagramFeed from './instagramFeed'
import Slider from './slider'
import McAjaxSubmit from './mcAjaxSubmit'
import Accordion from './accordion'
import Calendar from './calendar'
import NewPage from './new'
import SingleArtistBooking from './singleArtistBooking'


let self

export default self = {

  init : () => {
    Barba.Pjax.init();
    Barba.Prefetch.init();

    // Google Analytics
    Barba.Dispatcher.on('initStateChange', function() {
      if (typeof ga === 'function') {
        ga('send', 'pageview', location.pathname);
      }
    });

    //---------------------------------------//
    //       When Transition Completed       //
    //---------------------------------------//
    Barba.Dispatcher.on('transitionCompleted', function(currentStatus) {

        // Prevent Reloading When User click a link that is a same page as a current page
        const links = document.querySelectorAll('a[href]');
        const cbk = function(e) {
        if(e.currentTarget.href === window.location.href) {
        e.preventDefault();
        e.stopPropagation();
        }
        };
        for(let i = 0; i < links.length; i++) {
        links[i].addEventListener('click', cbk);
        }

    });

    let lastElementClicked;

    Barba.Dispatcher.on('linkClicked', function(el) {
        lastElementClicked = el;
    });

    //---------------------------------------//
    //               Basic                   //
    //---------------------------------------//
    const Basic = Barba.BaseView.extend({
        namespace: "basic",
        Nav: null,
        ScrollRevealSiegel: null,
        McAjaxSubmit: null,
        onEnter: function() {
            this._construct();
        },
        onEnterCompleted: function() {
            this.start();
        },
        _construct: function() {
            this.destroy();
        },
        destroy: function() {
            this.Nav = null;
            this.HoverEffect = null;
            this.ScrollRevealSiegel = null;
            this.McAjaxSubmit = null;
        },
        start: function() {
            this.Nav = Nav;
            this.Nav.init();
            this.HoverEffect = HoverEffect;
            this.HoverEffect.init();
            this.ScrollRevealSiegel = ScrollRevealSiegel;
            this.ScrollRevealSiegel.init();
            this.McAjaxSubmit = McAjaxSubmit;
            this.McAjaxSubmit.init();

        }
    });
    Basic.init();

    //---------------------------------------//
    //               NewPage                 //
    //---------------------------------------//
    const New = Barba.BaseView.extend({
        namespace: "newpage",
        Nav: null,
        ScrollRevealSiegel: null,
        McAjaxSubmit: null,
        NewPage: null,
        onEnter: function() {
            this._construct();
        },
        onEnterCompleted: function() {
            this.start();
        },
        _construct: function() {
            this.destroy();
        },
        destroy: function() {
            this.Nav = null;
            this.HoverEffect = null;
            this.ScrollRevealSiegel = null;
            this.McAjaxSubmit = null;
            this.NewPage = null;
        },
        start: function() {
            this.Nav = Nav;
            this.Nav.init();
            this.HoverEffect = HoverEffect;
            this.HoverEffect.init();
            this.ScrollRevealSiegel = ScrollRevealSiegel;
            this.ScrollRevealSiegel.init();
            this.McAjaxSubmit = McAjaxSubmit;
            this.McAjaxSubmit.init();
            this.NewPage = NewPage;
            this.NewPage.init();
        }
    });
    New.init();

    //---------------------------------------//
    //               About                   //
    //---------------------------------------//
    const About = Barba.BaseView.extend({
        namespace: "about",
        Nav: null,
        ScrollRevealSiegel: null,
        InstagramFeed: null,
        McAjaxSubmit: null,
        onEnter: function() {
            this._construct();
        },
        onEnterCompleted: function() {
            this.start();
        },
        _construct: function() {
            this.destroy();
        },
        destroy: function() {
            this.Nav = null;
            this.HoverEffect = null;
            this.ScrollRevealSiegel = null;
            this.InstagramFeed = null;
            this.McAjaxSubmit = null;
        },
        start: function() {
            this.Nav = Nav;
            this.Nav.init();
            this.HoverEffect = HoverEffect;
            this.HoverEffect.init();
            this.ScrollRevealSiegel = ScrollRevealSiegel;
            this.ScrollRevealSiegel.init();
            this.InstagramFeed = InstagramFeed;
            this.InstagramFeed.init();
            this.McAjaxSubmit = McAjaxSubmit;
            this.McAjaxSubmit.init();

        }
    });
    About.init();

    //---------------------------------------//
    //               Artists                 //
    //---------------------------------------//
    const Artists = Barba.BaseView.extend({
        namespace: "artists",
        Nav: null,
        ScrollRevealSiegel: null,
        Slider: null,
        McAjaxSubmit: null,
        Accordion: null,
        onEnter: function() {
            this._construct();
        },
        onEnterCompleted: function() {
            this.start();
        },
        _construct: function() {
            this.destroy();
        },
        destroy: function() {
            this.Nav = null;
            this.HoverEffect = null;
            this.ScrollRevealSiegel = null;
            this.Slider = null;
            this.McAjaxSubmit = null;
            this.Accordion = null;
        },
        start: function() {
            this.Nav = Nav;
            this.Nav.init();
            this.HoverEffect = HoverEffect;
            this.HoverEffect.init();
            this.ScrollRevealSiegel = ScrollRevealSiegel;
            this.ScrollRevealSiegel.init();
            this.Slider = Slider;
            this.Slider.init();
            this.McAjaxSubmit = McAjaxSubmit;
            this.McAjaxSubmit.init();
            this.Accordion = Accordion;
            this.Accordion.init();

        }
    });
    Artists.init();

    //---------------------------------------//
    //               Home                    //
    //---------------------------------------//
    const Home = Barba.BaseView.extend({
        namespace: "home",
        Nav: null,
        Video: null,
        HoverEffect: null,
        ScrollRevealSiegel: null,
        McAjaxSubmit: null,
        onEnter: function() {
            this._construct();
        },
        onEnterCompleted: function() {
            this.start();
        },
        _construct: function() {
            this.destroy();
        },
        destroy: function() {
            this.Nav = null;
            this.Video = null;
            this.HoverEffect = null;
            this.Loading = null;
            this.McAjaxSubmit = null;
        },
        start: function() {
            this.Nav = Nav;
            this.Nav.init();
            this.Loading = Loading;
            this.Loading.init();
            this.Video = Video;
            this.Video.init();
            this.HoverEffect = HoverEffect;
            this.HoverEffect.init();
            this.ScrollRevealSiegel = ScrollRevealSiegel;
            this.ScrollRevealSiegel.init();
            this.McAjaxSubmit = McAjaxSubmit;
            this.McAjaxSubmit.init();


        }
    });
    Home.init();

    //---------------------------------------//
    //           Single Project              //
    //---------------------------------------//
    const SingleProject = Barba.BaseView.extend({
        namespace: "single-project",
        Nav: null,
        Video: null,
        McAjaxSubmit: null,
        onEnter: function() {
            this._construct();
        },
        onEnterCompleted: function() {
            this.start();
        },
        _construct: function() {
            this.destroy();
        },
        destroy: function() {
            this.Nav = null;
            this.Video = null;
            this.McAjaxSubmit = null;

        },
        start: function() {
            this.Nav = Nav;
            this.Nav.init();
            this.Video = Video;
            this.Video.init();
            this.McAjaxSubmit = McAjaxSubmit;
            this.McAjaxSubmit.init();
        }
    });
    SingleProject.init();

    //---------------------------------------//
    //           Single Artist               //
    //---------------------------------------//
    const SingleArtistPage = Barba.BaseView.extend({
      namespace: "single-artist",
      Nav: null,
      Calendar: null,
      SingleArtist: null,
      onEnter: function() {
          this._construct();
      },
      onEnterCompleted: function() {
          this.start();
      },
      _construct: function() {
          this.destroy();
      },
      destroy: function() {
          this.Nav = null;
          this.Calendar = null;
          this.SingleArtistBooking = null;
      },
      start: function() {
          this.Nav = Nav;
          this.Nav.init();
          this.Calendar = Calendar;
          this.Calendar.init();
          this.SingleArtistBooking = SingleArtistBooking;
          this.SingleArtistBooking.init();
      }
  });
  SingleArtistPage.init();

    //---------------------------------------//
    //            Page Transition            //
    //---------------------------------------//

    function logo() {
        const logoLink = document.querySelector('#logo-animation a');
        if(logoLink){
            const logoSvg = document.querySelector('#logo-animation a svg');
            if(logoSvg){
                logoLink.removeChild(logoLink.childNodes[0]);
            }

            const logo = new bodyMovin({
                elm: logoLink,
                animationData: 'logo'
              });
            return logo.play();
        }
    }


    const PageAnimation = Barba.BaseTransition.extend({

        start: function() {
        if(lastElementClicked.parentElement.classList.contains('menu-item')){

            Promise
                .all([this.newContainerLoading])
                .then(this.pageCheck.bind(this))
                .then(this.navAnimation.bind(this));
            } else {
            Promise
                .all([this.newContainerLoading])
                .then(this.pageCheck.bind(this))
                .then(this.fadeInOut.bind(this));
            };
        },

        fadeInOut: function() {
            const _this = this;
            const tl = new TimelineLite();

            return tl
            .set([this.newContainer, '.header-container'], {y: '30px', autoAlpha: 0})
            .to(this.oldContainer, 0.6, {autoAlpha: 0})
            .set(this.oldContainer, {display: 'none'})
            .to(window, 0.001, {scrollTo:'body'}, '-=0.2')
            .add('new')
            .to('.header-container', 0.6, {y: '0px', autoAlpha: 1}, 'new')
            .call(logo, [], this, 'new')
            .to(this.newContainer, 0.6, {y: '0px', autoAlpha: 1, onComplete: function() {
                TweenLite.set([_this.newContainer, '.header-container'], { clearProps: 'all' });
                _this.done();
            }}, 'new');
        },

        navAnimation: function() {
            const _this = this;
            const tl = new TimelineLite();
            const navList = [...document.querySelectorAll('.navigation a')];
            const mainNavRight = document.querySelector('.main-nav__right');
            const body = document.querySelector('body');

            return tl
            .add('begin')
            .staggerTo(navList, 0.4, {y: '100%'}, 0.05, 'begin')
            .to(mainNavRight, 0.4, {y: '-10%', autoAlpha: 0}, 'begin')
            .set('.header-container', {y: '30px', autoAlpha: 0}, 'begin')
            .add('start')
            .set(body, {className: '-=is-open', overflow: 'visible'}, 'start')
            .to(this.oldContainer, 0.1, {autoAlpha: 0, display: 'none'}, 'start')
            .set(this.newContainer, {y: '30px', autoAlpha: 0})
            .to(window, 0.01, {scrollTo:'body'})
            .add('new')
            .to('.header-container', 0.5, {y: '0px', autoAlpha: 1}, 'new')
            .call(logo, [], this, 'new')
            .to(this.newContainer, 0.5, {y: '0px', autoAlpha: 1, onComplete: function() {
                TweenLite.set([_this.newContainer, '.header-container',], { clearProps: 'all' });
                _this.done();
            }}, 'new');
        },

        pageCheck: function () {
            const page = this.newContainer.dataset.namespace;
            if(page !== 'home'){
                this.newContainer.ownerDocument.body.classList.remove('front');
            } else {
                this.newContainer.ownerDocument.body.classList.add('front');
            }
            if (page === 'single' || page === 'single-artist'){
                this.newContainer.ownerDocument.body.classList.add('single-profile');
            } else {
                this.newContainer.ownerDocument.body.classList.remove('single-profile');
            }

            const url = this.newContainer.baseURI;
            const navList = [...document.querySelectorAll('.navigation a')];

            navList.forEach((list)=>{
                list.classList.remove('active');
                if(list.href == url){
                    list.classList.add('active');
                }
            });
        }

    });

    Barba.Pjax.getTransition = function() {
      return PageAnimation;
    };

  }
}
