import bodymovin from 'bodymovin';
import logo from '../json/logo.json';

export default class CustomBodymovin {
  constructor(opts={}) {
    this.elm = opts.elm || document.createElement('div');
    this.animationData = opts.animationData;
    this.WIDTH = 1920;
    this.HEIGHT = 1080;
    this.init();
    this.initListener();
  }

  init() {
    this.selectAnimationData();
    this.anim = bodymovin.loadAnimation({
      container: this.elm,
      renderer: "svg",
      loop: false,
      autoplay: false,
      autoloadsegments:true,
      rendererSettings:{
        progressiveLoad:false
      },
      animationData: this.animationData
    });
  }

  initListener() {

    this._onResize();
    window.addEventListener('resize', () => {
      this._onResize();
    },false)

  }

  selectAnimationData() {
    switch (this.animationData) {
      case 'logo': this.animationData = logo; break;
    }
  }

  play(){
    this.anim.play();
  }
  stop(){
    this.anim.stop();
  }
  pause(){
    this.anim.pause();
  }
  destroy(){
    this.anim.destroy();
  }
  setSpeed(int){
    this.anim.setSpeed(int);
  }

  _onResize() {
    const ww = window.innerWidth;
    const wh = window.innerHeight;
    const ratio = ww / this.WIDTH;
    const scaleY = wh / (this.HEIGHT * ratio);
    const svg = this.elm.querySelector('svg');
    // console.log(svg);
    // svg.style.transform = `translate(-50%, -50%) scale3d(1, ${scaleY}, 1)`;
  }

}