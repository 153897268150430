'use strict'

import fetch from 'isomorphic-unfetch'
import $ from 'jquery'

let self;

export default self = {
  container: document.querySelector('.single-artist__wrapper'),
  bookBtn: document.querySelector('.single-artist__contact'),
  detailBtn: document.querySelector('.btn-artist-detail'),
  infoContent: document.querySelector('[data-content="info"]'),
  bookingContent: document.querySelector('[data-content="booking"]'),

  init: () => {
    self.setupElements();
    self.eventHandler();
  },

  setupElements: () => {
    self.container = document.querySelector('.single-artist__wrapper')
    self.bookBtn = document.querySelector('.single-artist__contact')
    self.detailBtn = document.querySelector('.btn-artist-detail')
    self.infoContent = document.querySelector('[data-content="info"]')
    self.bookingContent = document.querySelector('[data-content="booking"]')
    self.bookingRequestForm = document.querySelector('.booking-request-form')
  },

  eventHandler : () => {
    self.bookBtn.addEventListener('click', (e) => {
      self.infoContent.classList.remove('is-show');
      self.bookingContent.classList.add('is-display');
      setTimeout(() => {
        self.infoContent.classList.remove('is-display');
        self.bookingContent.classList.add('is-show');
      }, 300);
    });

    self.detailBtn.addEventListener('click', (e) => {
      self.bookingContent.classList.remove('is-show');
      self.infoContent.classList.add('is-display');
      setTimeout(() => {
        self.bookingContent.classList.remove('is-display');
        self.infoContent.classList.add('is-show');
      }, 300);
    });

    self.bookingRequestForm.addEventListener('submit', self.submitBookingRequest)
  },

  submitBookingRequest : async e => {
    e.preventDefault()
    let body = {}
    const form = e.currentTarget
    const { method, action } = form
    const inputs = [...form.elements]

    inputs.map( input => input.name ? body[input.name] = input.value : null )

    $.ajax({
     type : method,
     url : action,
     data : $(form).serialize(),
     success : data => console.log(data)
    })
  }
}
