'use strict';

import { TimelineLite, TweenLite } from 'gsap';

let self;
export default self = {
	elems: {
        body: 			null,
		loadingLogo: 	null
    },

	init : () => {

		// Init
		self.elems.body = document.querySelector('body');
		self.elems.loadingLogo = document.querySelector('.loadaing-logo');

		self.show();
	},

	show : () => {
		if (self.elems.body.classList.contains('loading')) {
			const tlShow = new TimelineLite();

			if ( self.elems.loadingLogo !== null ) {
				tlShow
				.set(self.elems.loadingLogo, { autoAlpha: 0 })
				.to(self.elems.loadingLogo, 0.8, { autoAlpha: 1, delay: 0.3 })
				.to(self.elems.loadingLogo, 1.2, { top: '0', autoAlpha: 0, delay: 0.2, ease: Power2.easeInOut })
				.set(self.elems.body, { className: '-=loading' });
			} else {
				tlShow
				.set(self.elems.body, { className: '-=loading' });
			}
		} 
	}

}