'use strict'

let self;

export default self = {
  container: document.querySelectorAll('.accordion-box'),
  openBtn: document.querySelectorAll('.accordion-open'),

  init: () => {
    self.container = document.querySelectorAll('.accordion-box');
    self.openBtn = document.querySelectorAll('.accordion-open');

    if (self.openBtn) {
      self.accordionBtn();
    }
  },

  accordionBtn: () => {
    [...self.openBtn].forEach((element) => {
      element.addEventListener('click', function (e) {

        if (element.dataset.state == 'close') {
          element.previousElementSibling.style.maxHeight = 800 + "px";
          element.dataset.state = 'open';
          element.innerHTML = 'Close';
        } else {
          element.previousElementSibling.style.maxHeight = 0 + "px";
          element.dataset.state = 'close';
          element.innerHTML = 'Read More';
        }

      });
    });
  },
}