
import $ from 'jquery'

let self

export default self = {
    container : document.getElementById('mc_embed_signup'),

    init : () => {
        self.setupElements()
        self.registerEvents()
    },

    setupElements : () => {
        self.form = document.getElementById('mc-embedded-subscribe-form')
        self.resultsElm = document.querySelector('.results')
        self.resultsSuccessElm = self.resultsElm.querySelector('.results__success')
        self.resultsErrorElm = self.resultsElm.querySelector('.results__error')
    },

    registerEvents : () => {
        self.form.addEventListener("submit", self.onSubmit)
    },

    onSubmit : e => {
        e.preventDefault()
        const ajaxOpts = self.getAjaxOptions()
        return $.ajax(ajaxOpts)
    },

    onSubmitSuccess : (res) => {
        const { msg, result } = res

        self.setState(result)

        if (result != "success") {
            self.resultsErrorElm.querySelector('.msg').innerHTML = msg
            return false
        }

        // NOTE: success message is hardcoded in the template
        // return self.resultsSuccessElm.querySelector('.msg').innerHTML = msg
    },

    getAjaxOptions : () => {
        return {
            url: self.form.getAttribute('action'),
            type: self.form.getAttribute('method'),
            data: $(self.form).serialize(),
            dataType: 'jsonp',
            contentType: "application/json; charset=utf-8",
            success: self.onSubmitSuccess
        }
    },

    setState : state => self.container.setAttribute("data-state", state)

}