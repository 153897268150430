'use strict';

import ScrollReveal from 'scrollreveal';

let self;
export default self = {
	elems: {
		reveal: 	null
    },

	init : () => {

		// Init
		self.elems.reveal = document.querySelector('.scroll-reveal');
		window.sr = ScrollReveal();

		if (self.elems.reveal) {
			sr.reveal('.scroll-reveal', {
				duration: 1100,
				distance: '50px',
				scale: 0.95,
				afterReveal: function (element) {
					element.classList.add('is-active');
				}
			});
		}
	},

}