'use strict';

import { TimelineLite, TweenLite } from 'gsap';
import plyr from 'plyr';

let self;
export default self = {
    container: document.querySelector('.video-popup'),
    elems: {
        body:       null,
        btnPlay:    null,
        btnClose:   null,
        videoPopup: null,
        coverTop:   null,
        coverBtm:   null,
        video:      null,
        players:    null,
        player:     null,
    },

	init : () => {

        // Init
        self.elems.body = document.querySelector('body');
        self.elems.btnPlay = document.querySelector('.btn-play');
        self.elems.textMouseover = document.querySelector('.text-mouseover');
        self.elems.btnClose = document.querySelector('.btn-close');
        self.elems.videoPopup = document.querySelector('.video-popup');
        self.elems.coverTop = document.querySelector('.video-popup__cover--top');
        self.elems.coverBtm = document.querySelector('.video-popup__cover--bottom');
        self.elems.video = document.getElementById('popup-video');

        // Show "Watch Full Video" when mouseover
        self.elems.btnPlay.addEventListener('mouseover', () => {
            self.elems.textMouseover.classList.add('active');
        });
        self.elems.btnPlay.addEventListener('mouseout', () => {
            self.elems.textMouseover.classList.remove('active');
        });

        // Play Video
		self.elems.btnPlay.addEventListener('click', () => {
			self.open();
        });

        // Close Video
        self.elems.btnClose.addEventListener('click', () => {
			self.close();
        });
        // Plyr.setup
        // self.players = new Plyr('#popup-video');
        // plyr.setup();
        var instances = plyr.setup('#popup-video');
        self.players = instances[0];
	},

	open : () => {
        const tlOpen = new TimelineLite();
        tlOpen
            .set(self.elems.body, { overflow: 'hidden' })
            .to(self.elems.videoPopup, 0.6, { autoAlpha: 1 })
            .add(() => {
                // console.log(self.players);
                self.players.play();
                TweenLite.to(self.elems.coverTop, 2, { y: '-100%' });
                TweenLite.to(self.elems.coverBtm, 2, { y: '100%' });
            })

    },
    
    close : () => {
        const tlClose = new TimelineLite();
        tlClose
            .add('close')
            .to(self.elems.coverTop, 1.6, {y: '0%'}, 'close')
            .to(self.elems.coverBtm, 1.6, {y: '0%'}, 'close')
            .to(self.elems.videoPopup, 0.6, {autoAlpha: 0})
            .set(self.elems.body, {overflow: 'visible'})
            .add(() => self.players.pause());
    }
}