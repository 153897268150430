'use strict'

import $ from 'jquery'

let self

export default self = {
    container : document.getElementById('instagramFeed'),
    count : 12,
    imageContainer : null,
    api : {
        client_id : "97f527e67e324c5cb67396c0548b409e",
        client_secret : "5e868c13664b44dc9cfe3f54f0667365",
        token : "3104585837.97f527e.7043d98acd644de1a430d3deaaa0a919"
    },

    init : () => {
        self.count = 12;
        self.api.client_id = "97f527e67e324c5cb67396c0548b409e";
        self.api.client_secret = "5e868c13664b44dc9cfe3f54f0667365";
        self.api.token = "3104585837.97f527e.7043d98acd644de1a430d3deaaa0a919";

        self.getImages()
    },

    getImages : () => $.ajax({
        url : `https://api.instagram.com/v1/users/self/media/recent/?access_token=${self.api.token}`,
        dataType : "jsonp",
        crossDomain : true,
        contentType: "application/json; charset=utf-8",
        type: "GET"
    }).fail(err => {
        console.log("[getImages] Fail", err);
    }).done(res => {
        console.log("[getImages] Done", res);
        self.insertImages(res);
    }),

    insertImages : res => {
        let image, text, content
        let imageContainer = document.querySelector('.images');


        for (var i = 0; i < self.count; i++) {

            text = res.data[i].caption.text;
            content = text.split(" ").slice(0, 10).join(' ');
            
            image = `
            <a class="insta-feed__img" target="_blank" href="${res.data[i].link}">
                <div class="text">${content}...</div>
                <div class="meta flex-box">
                    <div class="meta__left">
                        <span><i class="fas fa-heart"></i>${res.data[i].likes.count}</span>
                        <span><i class="fas fa-comments"></i>${res.data[i].comments.count}</span>
                    </div>
                    <div class="meta__right"><i class="fab fa-instagram"></i></div>
                </div>
                <div class="bg-image" style="background-image: url(${res.data[i].images.standard_resolution.url});"></div>
            </a>`;
            
            imageContainer.innerHTML += image;
        }

    }
}