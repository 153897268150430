'use strict';

// Dependancies
import "babel-polyfill"

import Nav from './modules/nav'
import Loading from './modules/loading'
import PageTransition from './modules/pageTransition'
import ScrollRevealSiegel from './modules/scrollReveal'
import Video from './modules/video'
import HoverEffect from './modules/hoverEffect'
import bodyMovin from './modules/bodyMovin'
import InstagramFeed from './modules/instagramFeed'
import Slider from './modules/slider'
import McAjaxSubmit from './modules/mcAjaxSubmit'
import Accordion from './modules/accordion'
import Calendar from './modules/calendar'
import SingleArtistBooking from './modules/singleArtistBooking'
import NewPage from './modules/new'
import $ from 'jquery'

if ( ! (document.querySelector('body').classList.contains('page-template-page-template-new') || document.querySelector('body').classList.contains('blog') || document.querySelector('body').classList.contains('single-post') ) ) {
Nav.init();
PageTransition.init();



HoverEffect.init();
ScrollRevealSiegel.init();

if (InstagramFeed.container) {
  InstagramFeed.init()
}


Slider.init()

if (Accordion.container) {
  Accordion.init()
}

if (Calendar.container) {
  Calendar.init()
}


if (SingleArtistBooking.container) {
  SingleArtistBooking.init()
}

} else {
  
  // document.querySelector('.video-container__x').addEventListener('click', function() {
  //   document.querySelector('.video-container').style.display = "none";
  // });
  
  if ( window.matchMedia("only screen and (max-width: 991px)").matches ) {
    Nav.init();
  }
}

McAjaxSubmit.init();

if (Video.container) {
  Video.init()
}

Loading.init();

NewPage.init();

const logo = new bodyMovin({
  elm: document.querySelector('#logo-animation a'),
  animationData: 'logo'
});

logo.play();