'use strict';

// import svg4everybody from 'svg4everybody'
import { TimelineLite, TweenLite } from 'gsap';

let self;
export default self = {
	elems: {
        body: 			null,
		mainNav: 		null,
		mainNavRight: 	null,
		navlist: 		null,
		hamburger: 		null,
		lastScrollTop: 	null
    },

	init : () => {

		// Init
		self.elems.body = document.querySelector('body');
		self.elems.mainNav = document.querySelector('.main-nav');
		self.elems.mainNavRight = document.querySelector('.main-nav__right');
		self.elems.navlist = document.querySelectorAll('.navigation a');
		self.elems.hamburger = document.querySelector('.hamburger');

		self.elems.hamburger.addEventListener('click', ()=>{
			if(!self.elems.body.classList.contains('is-animating')){
				self.toggle();
			}
		});


		// svg4everybody();
		window.addEventListener('scroll', self.debounce(self.onScroll));
	},

	toggle : () => {
		if(!self.elems.body.classList.contains('is-open')){
			self.openNav();
		} else {
			self.closeNav();
		}
	},

	openNav : () => {
		const tlOpen = new TimelineLite();
		tlOpen
			.set(self.elems.navlist, {y: '100%'})
			.set(self.elems.mainNavRight, {y: '-10%', autoAlpha: 0})
			.set(self.elems.body, {className: '+=is-animating'})
			.set(self.elems.body, {className: '+=is-open', overflow: 'hidden'})
			.add('start', '+=0.5')
			.staggerTo(self.elems.navlist, 0.4, {y: '0%'}, -0.05, 'start')
			.to(self.elems.mainNavRight, 0.4, {y: '0%', autoAlpha: 1}, 'start')
			.set(self.elems.body, {className: '-=is-animating'});
	},

	closeNav : () => {
		const tlClose = new TimelineLite();
		tlClose
			.set(self.elems.body, {className: '+=is-animating'})
			.add('start')
			.staggerTo(self.elems.navlist, 0.4, {y: '100%'}, 0.05, 'start')
			.to(self.elems.mainNavRight, 0.4, {y: '-10%', autoAlpha: 0}, 'start')
			.set(self.elems.body, {className: '-=is-open', overflow: 'visible'})
			.set(self.elems.body, {className: '-=is-animating'});
	},

	onScroll : e => {

		let st = window.pageYOffset || document.documentElement.scrollTop;
		const navbarHeight = 200;
		if (st > self.elems.lastScrollTop && st > navbarHeight){
			self.elems.body.classList.add('is-up');
			self.elems.body.classList.remove('is-down');
		} else {
			if(st > navbarHeight) {
				self.elems.body.classList.remove('is-up');
				self.elems.body.classList.add('is-down');
			} else {
				self.elems.body.classList.remove('is-up');
				self.elems.body.classList.remove('is-down');
			}
		}
		self.elems.lastScrollTop = st;
	},
	
	debounce : (func, wait = 10, immediate = true) => {
		var timeout;
		return function() {
			var context = this, args = arguments;
			var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	}
}