'use strict';

import {
  TimelineLite,
  TweenMax
} from 'gsap';

let self;
export default self = {
  container: document.querySelector('.home-main'),
  elems: {
    body: null,
    serviceList: null,
    bgList: null,
    projectShowcaseLinks: null,
    wheel: null,
  },

  init: () => {

    // Init
    self.container = document.querySelector('.home-main');
    self.elems.body = document.querySelector('body');
    self.elems.serviceList = document.querySelectorAll('.home-services-nav__list');
    self.elems.bgList = document.querySelectorAll('.home-services-bg__list');
    self.elems.projectShowcaseLinks = [...document.querySelectorAll('.link-unit')];
    self.elems.wheel = [...document.querySelectorAll('.home-services-wheel img')];

    if (self.container) {

      [...self.elems.serviceList].forEach((list) => {
        list.addEventListener('mouseover', (e) => {
          const serviceName = list.dataset.service;

          [...self.elems.bgList].forEach((l) => {
            l.classList.remove('active');
            if (serviceName == l.dataset.service) {
              l.classList.add('active');
            }
          });

          [...self.elems.wheel].forEach((w) => {
            w.classList.remove('active');
            if (serviceName == w.dataset.service) {
              w.classList.add('active');
            }
          });
        });
      });
    }

    if (self.elems.projectShowcaseLinks) {
      self.elems.projectShowcaseLinks.forEach((c) => {
        c.addEventListener('mouseover', e => {
          self.mouseEventHandler(e, c);
        });
        c.addEventListener('mouseout', e => {
          self.mouseEventHandler(e, c);
        });
      });
    }

  },

  mouseEventHandler: (e, c) => {
    let parent = e.currentTarget.parentNode.parentNode;
    if (e.type == 'mouseover') {
      parent.classList.add('hover');
    }
    if (e.type == 'mouseout') {
      parent.classList.remove('hover');
    }
  }
}