'use strict';

import fetch from 'isomorphic-unfetch'
import Datepickk from "./dataPicker"
import moment from 'moment'

let self;
export default self = {
  container: document.getElementById('demoPicker'),
  dateInput: document.querySelector('.date-input'),

	init : () => {
    self.container = document.getElementById('demoPicker')
    self.dateInput = document.querySelector('.date-input')

    return self.setupCalendar()
	},

  setupCalendar : async () => {
    const bookings = await self.getArtistBookings()
    const minDate = new Date();


    // Set disabled dates
    const disabledDates = bookings.map( booking => {
      const [ year, month, day ] = booking.date.split('-').map( x => parseInt(x) )
      return new Date(year, month, day)
    })

    disabledDates.push( new Date() )


    // Setup calendar
    const demoPicker = new Datepickk({
      container : self.container,
      inline : true,
      minDate : minDate.setDate(minDate.getDate() - 1),
    });

    demoPicker.disabledDates = disabledDates

    demoPicker.onSelect = checked => {
      const selectedDates = demoPicker.selectedDates.map( date => moment(date).format('YYYY/MM/DD') )
      self.dateInput.value = selectedDates.join(' | ')
    }
  },

  getArtistBookings : async () => {
    const ovid = self.getOVID()
    const dbAddr = self.getDbAddr()
    const req = await fetch(`http://${dbAddr}/api/bookings/${ovid}`)
    const res = await req.json()

    return res
  },

  getOVID : () => document.querySelector('.single-artist').getAttribute('data-ovid'),
  getDbAddr : () => location.host === 'siegelent.test' ? '127.0.0.1:3000' : '138.68.28.103:3000'

}
